export const sceneCfg = {
  1920: {
    res: 1920,
    camera: {
      x: 8,
      y: -7,
      z: 21,
      lookAtX: 3,
      lookAtY: 2,
      lookAtZ: 7,
    },
    model: {
      x: 0,
      y: 0,
      z: 5,
      rotationMinX: 0.05,
      rotationMaxX: 0.2,
      rotationMinY: 0,
      rotationMaxY: 0.05,
      normal: -200,
    },
    controls: {
      azMin: -0.1,
      azMax: 0.5,
      polMin: 1,
      polMax: 2,
    },
    light1: {
      x: -15,
      y: 10,
      z: 15,
      intensity: 70,
      decay: 0.7,
      r: 8,
      g: 5,
      b: 2,
    },
    light2: {
      x: 20,
      y: -10,
      z: -12,
      intensity: 70,
      decay: 0,
      r: 1,
      g: 1,
      b: 1,
    },
  },
  1440: {
    res: 1440,
    camera: {
      x: 7,
      y: -10,
      z: 17,
      lookAtX: 2,
      lookAtY: 3,
      lookAtZ: 0,
    },
    model: {
      x: 0,
      y: 0,
      z: 0,
      // rotationMinX: -0.1,
      // rotationMaxX: 0.2,
      // rotationMinY: 0.16,
      // rotationMaxY: 0.27,
      rotationMinX: -0.1,
      rotationMaxX: 0.6,
      rotationMinY: 0.15,
      rotationMaxY: 0.35,
      normal: -130,
    },
    controls: {
      azMin: -0.3,
      azMax: 0.6,
      polMin: 1,
      polMax: 2,
    },
    light1: {
      x: -15,
      y: 10,
      z: 15,
      intensity: 70,
      decay: 0.7,
      r: 8,
      g: 5,
      b: 2,
    },
    light2: {
      x: 20,
      y: -10,
      z: -12,
      intensity: 70,
      decay: 0,
      r: 1,
      g: 1,
      b: 1,
    },
  },
  1100: {
    res: 1100,
    camera: {
      x: 7,
      y: -12,
      z: 20,
      lookAtX: 2,
      lookAtY: 1,
      lookAtZ: 3,
    },
    model: {
      x: 0,
      y: 0,
      z: 0,
      rotationMinX: -0.15,
      rotationMaxX: 0.15,
      rotationMinY: 0.12,
      rotationMaxY: 0.2,
      normal: -130,
    },
    controls: {
      azMin: -0.7,
      azMax: 0.7,
      polMin: 0.4,
      polMax: 2.6,
    },
    light1: {
      x: -15,
      y: 10,
      z: 15,
      intensity: 70,
      decay: 0.7,
      r: 8,
      g: 5,
      b: 2,
    },
    light2: {
      x: 20,
      y: -10,
      z: -12,
      intensity: 70,
      decay: 0,
      r: 1,
      g: 1,
      b: 1,
    },
  },
  786: {
    res: 786,
    camera: {
      x: 7,
      y: -10,
      z: 20,
      lookAtX: 2,
      lookAtY: 1,
      lookAtZ: 3,
    },
    model: {
      x: 0,
      y: 0,
      z: 0,
      rotationMinX: -0.2,
      rotationMaxX: 0.4,
      rotationMinY: 0.13,
      rotationMaxY: 0.2,
      normal: -130,
    },
    controls: {
      azMin: -0.4,
      azMax: 0.8,
      polMin: 1.2,
      polMax: 2.1,
    },
    light1: {
      x: -15,
      y: 10,
      z: 15,
      intensity: 70,
      decay: 0.7,
      r: 8,
      g: 5,
      b: 2,
    },
    light2: {
      x: 20,
      y: -10,
      z: -12,
      intensity: 70,
      decay: 0,
      r: 1,
      g: 1,
      b: 1,
    },
  },
  // 420: {
  //   res: 420,
  //   camera: {
  //     x: 16,
  //     y: -9,
  //     z: 15,
  //     lookAtX: 10,
  //     lookAtY: -5,
  //     lookAtZ: 9,
  //   },
  //   model: {
  //     x: -5,
  //     y: 0,
  //     z: -5,
  //     rotationMinX: -0.3,
  //     rotationMaxX: 0.3,
  //     rotationMinY: 0.4,
  //     rotationMaxY: 0.7,
  //   },
  //   controls: {
  //     azMin: 0.6,
  //     azMax: 0.9,
  //     polMin: 1.8,
  //     polMax: 2,
  //   },
  //   light1: {
  //     x: -15,
  //     y: 10,
  //     z: 15,
  //     intensity: 70,
  //     decay: 0.7,
  //     r: 8,
  //     g: 5,
  //     b: 2,
  //   },
  //   light2: {
  //     x: 20,
  //     y: -10,
  //     z: -12,
  //     intensity: 70,
  //     decay: 0,
  //     r: 1,
  //     g: 1,
  //     b: 1,
  //   },
  // },
  375: {
    res: 375,
    camera: {
      x: 16,
      y: -9,
      z: 15,
      lookAtX: 10,
      lookAtY: -5,
      lookAtZ: 9,
    },
    model: {
      x: -5,
      y: -1,
      z: -5,
      rotationMinX: -0.3,
      rotationMaxX: 0.3,
      rotationMinY: 0.4,
      rotationMaxY: 0.7,
      normal: -10,
    },
    controls: {
      azMin: 0.6,
      azMax: 0.9,
      polMin: 1.8,
      polMax: 2.1,
    },
    light1: {
      x: -15,
      y: 10,
      z: 15,
      intensity: 70,
      decay: 0.7,
      r: 8,
      g: 5,
      b: 2,
    },
    light2: {
      x: 20,
      y: -10,
      z: -12,
      intensity: 70,
      decay: 0,
      r: 1,
      g: 1,
      b: 1,
    },
  },
  // 320: {
  //   res: 320,
  //   camera: {
  //     x: 16,
  //     y: -9,
  //     z: 15,
  //     lookAtX: 10,
  //     lookAtY: -5,
  //     lookAtZ: 9,
  //   },
  //   model: {
  //     x: -5,
  //     y: -3,
  //     z: -5,
  //     rotationMinX: -0.3,
  //     rotationMaxX: 0.3,
  //     rotationMinY: 0.4,
  //     rotationMaxY: 0.7,
  //   },
  //   controls: {
  //     azMin: 0.6,
  //     azMax: 0.9,
  //     polMin: 1.6,
  //     polMax: 2,
  //   },
  //   light1: {
  //     x: -15,
  //     y: 10,
  //     z: 15,
  //     intensity: 70,
  //     decay: 0.7,
  //     r: 8,
  //     g: 5,
  //     b: 2,
  //   },
  //   light2: {
  //     x: 20,
  //     y: -10,
  //     z: -12,
  //     intensity: 70,
  //     decay: 0,
  //     r: 1,
  //     g: 1,
  //     b: 1,
  //   },
  // },
};